export async function init(operacao) {
  // let rt = null;
  switch (
    operacao
    // case "operations.getAssociacao":
    //   rt = await objectUtils.convertToSelectItens(
    //     await associacaoServices.getAssociacao(),
    //     "_id",
    //     ["nome", "nome"]
    //   );

    //   return rt;
    // case "operations.getFuncao":
    //   rt = await objectUtils.convertToSelectItens(
    //     await funcaoServices.getFuncao(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;
    // case "operations.getPapel":
    //   rt = await objectUtils.convertToSelectItens(
    //     await papelServices.getPapel(),
    //     "_id",
    //     ["name"]
    //   );

    //   return rt;
    // case "operations.getTiposPostagem":
    //   rt = await objectUtils.convertToSelectItens(
    //     await tipoPostagemServices.getTiposPostagem(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;
    // case "operations.getTipoEntrada":
    //   rt = await objectUtils.convertToSelectItens(
    //     await tipoEntradaServices.getTipoEntrada(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;
    // case "operations.getTipoEntradaPorAssociacaoAtual":
    //   rt = await objectUtils.convertToSelectItens(
    //     await associacaoTipoEntradaServices.getTipoEntradaPorAssociacaoAtual(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;

    // case "operations.getTipoSaida":
    //   rt = await objectUtils.convertToSelectItens(
    //     await tipoSaidaServices.getTipoSaida(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;
    // case "operations.getTipoSaidaPorAssociacaoAtual":
    //   rt = await objectUtils.convertToSelectItens(
    //     await associacaoTipoSaidaServices.getTipoSaidaPorAssociacaoAtual(),
    //     "_id",
    //     ["nome"]
    //   );

    //   return rt;
    // case "operations.getIdAssociacaoLogada":
    //   rt = tokenUtils.getIdAssociacaoFromAppToken();

    //   return rt;
    // case "operations.getIdMembroLogado":
    //   rt = tokenUtils.getIdMembroFromAppToken();

    //   return rt;
    // case "operations.getTipoEntradaParaOfertar":
    //   rt = await objectUtils.convertToSelectItens(
    //     await tipoEntradaServices.getTipoEntradaParaOfertar(),
    //     "_id",
    //     ["nome"]
    //   );
    //   return rt;
    // case "operations.getMembrosByAssociacao":
    //   rt = await objectUtils.convertToSelectItens(
    //     await membroServices.getMembrosByAssociacao(
    //       tokenUtils.getIdAssociacaoFromAppToken()
    //     ),
    //     "_id",
    //     ["nome"]
    //   );
    //   return rt;
    // case "operations.recuperarAreasDeNegocio":
    //   let valores = await junoServices.recuperarAreasDeNegocio();

    //   rt = await objectUtils.convertToSelectItens(
    //     valores._embedded.businessAreas,
    //     "code",
    //     ["category", "activity"]
    //   );
    //   return rt;
    // case "operations.recuperarBancos":
    //   let v = await junoServices.recuperarBancos();

    //   rt = await objectUtils.convertToSelectItens(v._embedded.banks, "number", [
    //     "number",
    //     "name",
    //   ]);
    //   return rt;
    // case "operations.recuperarEstadosBR":
    //   return await objectUtils.convertToSelectItens(
    //     EstadosBROptions.estados,
    //     "value",
    //     ["label"]
    //   );
  ) {
  }
}
