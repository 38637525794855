const messages = {
  pt: {
    translations: {
      links: {
        home: "Início",
        features: "Funcionalidades",
        screens: "Telas",
        howToStart: "Como começar",
        examBuilder: "Crie um simulado",
        runExam: "Estudar agora",
      },
      titles: {
        home1: "Crie seus simulados e estude pelo celular de onde estiver.",
        home2: `A plataforma JR Simulator permite que você tenha
        funcionalidades de simulação de prova igual a uma prova de
        certificação. Com contabilidade de tempo, nota e histórico de
        tentativa.`,
        home3: `Indicado para certificações em TI e provas de concursos.`,
        home4: `Veja como começar, clicando`,
        principals: `Principais`,
        features: `Funcionalidades`,
        feature1: `Crie e edite qualquer simulado`,
        feature2: `Histórico de performance`,
        feature3: `Embaralhar perguntas e respostas`,
        screens: `Telas do`,
        application: `Aplicativo`,
        how: `Como`,
        start: `começar?`,
        step1: `Crie o simulado`,
        step2: `Baixe o arquivo ".jr"`,
        step3: `Importe o arquivo no app`,
        creation: `Criação de`,
        exam: `simulador`,
        run: `Rodar`,
      },
      messages: {
        here: "aqui",
        click: "Clique",
        feature1: `Faça e modifique seu simulado configurando perguntas e
        respostas da forma que quiser.`,
        feature2: `Acompanhe a evolução de sua performance em cada tentativa.`,
        feature3: `Embaralhe perguntas e respostas para ter uma experiência mais
        desafiadora.`,
        step1: `e defina
        suas perguntas e respostas.`,
        step2: `Após terminar de criar o simulado, clique em Download para baixar o arquivo ".jr"`,
        step3: `Importe o arquivo ".jr" no app e dê o play.`,
        runExamMs1: `Carrege o arquivo ".jr" e realize o teste no tempo determinado.`,
        runExamMs2: `Minutos para realizar o teste`,
        runExamMs3: `Teste finalizado com sucesso`,

        builder1: `Adicione perguntas e suas possíveis respostas. Quando acabar, clique no botão download para baixar o simulado.`,
        builder2: `Pronto, agora é só carregar o simulado no app JR Simulator e começar a estudar.`,
        builder3: `Para criar o simulado por um xml, é necessário seguir um formato específico.`,
        builder4: `1- Baixe`,
        builder5: `o formato e altere as informações que precisar.`,
        builder6: `2- Garanta que o xml está válido antes de importar.`,
        builder7: `3- Importe o arquivo:`,
        builder8: `Para editar um arquivo .jr é simples.`,
        builder9: `1- Importe o arquivo, para carregar as perguntas e respostas automaticamente.`,
        builder10: `2- Altere como quiser e faça o download do arquivo editado.`,
        builder11: `Voce não pode criar uma nova questão sem informar qual resposta esta correta na pergunta anterior`,
        builder12: `Tamanho máximo permitido por imagem é de 15kb`,
        makeManual: `Criar manualmente`,
        makebyXML: `Crie a partir de um xml`,
        editJR: `Edite um arquivo .jr`,
        examTitle: `Título do simulado`,
        questions: `Questões:`,
        question: `Questão: `,
        multipleChoice: `Multipla escolha?`,
      },
      buttons: {
        save: "Salvar",
        downloadApp: "Baixar o App",
        runByWeb: "Rodar pelo browser",
        options: "Opções",
        addQuestion: "+Pergunta",
        downloadJr: "Baixar arquivo '.jr'",
        next: "Próximo",
        back: "Voltar",
        showAnswer: "Mostrar resposta",
        hideAnswer: "Esconder resposta",
        finalize: "Finalizar",
        startNow: "Começar",
      },
    },
  },
};

export { messages };
